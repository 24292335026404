import { NgModule } from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ObserversModule} from '@angular/cdk/observers';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import {ClipboardModule} from '@angular/cdk/clipboard';

const cdk = [
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    ObserversModule,
    DragDropModule,
    ScrollingModule,
    ClipboardModule,
    
 ];


@NgModule({
    declarations: [],
    imports: [
        cdk,
    ],
    exports: [ cdk]
})


export class CdkModules { }
