import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { logEvent } from 'firebase/analytics';
import { User } from 'firebase/auth';
import { DocumentData, QueryConstraint, getDocs, collection, getDoc, doc, deleteDoc, QuerySnapshot, DocumentReference, addDoc, setDoc, DocumentSnapshot, updateDoc, where } from 'firebase/firestore';
import { query, writeBatch } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { firestore, analytics, auth, functions } from '../../app.module';

export interface FileMetaData {
  id: string,
  name: string,
  size: number,
  file: File
}

@Injectable({
  providedIn: 'root',
})

export class FirebaseService {
  bugCount: number = 0;
  getFullPath(path: string) {
    if (path.includes(`clients/${this.clientId}`)) return path;
    return `clients/${this.clientId}/{${path.startsWith('/') ? path.replace('/', '') : path}}`
  }
  claims: any;
  userData: DocumentData | null = null;
  user: User | null = null;

  async handleError(err: any | string, message: string = '') {
    if (environment.production) {
      await this.logEvent('exception', { description: JSON.stringify(err), fatal: false });
      this.bugCount++
      const reportError = await this.userApproval(message != '' ? message : `Whoops, Something went wrong`, 'Report bug', 3000)
      if (reportError && this.bugCount > 3) return await this.pushglobal('bugs', { time: new Date().getTime(), error: JSON.stringify(err), user: await auth.currentUser != null ? auth.currentUser.uid : null }).then(() => this.userMessage('bug reported'))
      return;
    } else {
      console.log(JSON.stringify(err))
    };
  }

  log(string: any,) {
    if (environment.production) return null;
    console.info(string)
    return
    //  this.log(string)
  }

  logOb(obj: any) {
    return this.log(obj as string)
  }

  info(string: any) {
    if (environment.production) return null;
    return console.info(string)
  }

  async getRef(route: string) {
    return await doc(firestore, route).withConverter(null)
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where params
   * @returns 
   */
  async queryDocs(route: string, arg1: QueryConstraint): Promise<QuerySnapshot<DocumentData>> {
    if (!this.clientId) await this.fetchClientId(this.user)
    return getDocs(query(collection(firestore, `clients/${this.clientId}/${route}`), arg1))
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where params
   * @returns 
   */
  queryGlobalDocs(route: string, arg1: QueryConstraint): Promise<QuerySnapshot<DocumentData>> {
    return getDocs(query(collection(firestore, `${route}`), arg1))
  }

  aDoc(route: string): Promise<DocumentSnapshot<DocumentData>> {
    if (!this.clientId) this.fetchClientId(this.user)
    return getDoc(doc(firestore, `clients/${this.clientId}/${route}`))
  }

  async aGlobalDoc(route: string): Promise<DocumentData | null> {
    if (!this.clientId) await this.fetchClientId(this.user)
    return (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`))).data() ?? null;
  }
  /**
   * 
   * @param route /path to Object
   * @returns 
   */

  async getDoc(route: string): Promise<DocumentData | null> {
    if (!this.clientId) await this.fetchClientId(this.user)
    const value = await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`)))
    if (!value.exists) return null;
    return value;
  }

  async getDocData(route: string): Promise<DocumentData | null> {
    if (!this.clientId) await this.fetchClientId(this.user)
    const value = await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`)))
    if (!value.exists) return null;
    let data = value.data()
    if (!data) return null;
    data['key'] = value.id
    return data
  }
  async getGlobalDocData(route: string): Promise<DocumentData | null> {
    return (await getDoc(doc(firestore, `${route}`))).data() ?? null;
  }

  async getDocId(route: string): Promise<string> {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`))).id
  }
  /**
   * 
   * @param arg0 route to Object
   * @returns 
   */
  async removeDoc(route: string) {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await deleteDoc(doc(firestore, `clients/${this.clientId}/${route}`));
  }

  async removeDocGlobal(route: string) {
    return await deleteDoc(doc(firestore, `${route}`));
  }
  /**
   * 
   * @param route path to Object
   * @returns Object
   */
  async object(route: string) {
    return await this.getDocData(route)
  }
  /**
   * 
   * @param route path to Array of objects
   * @returns Array<any>
   */
  async getDocs(route: string): Promise<QuerySnapshot<DocumentData>> {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await getDocs(collection(firestore, `clients/${this.clientId}/${route}`))
  }

  /**
   * 
   * @param route route of data to be archived
   * @returns void
   */
  async archive(route: string) {
    return await this.set(route, { 'archive': true }, true).then(() => this.userMessage('archived'))
  }
  /**
   * 
   * @param route route to Array
   * @returns Array<any>
   */
  async list(route: string) {
    return (await this.getDocs(route)).docs.map((value) => {
      let data = value.data()
      data['key'] = value.id
      if (data['archive'] && data['archive'] == true) return null
      return data;
    }).filter((value) => value != null)
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where query
   * @returns Array<any>
   */
  async listquery(route: string, arg1: QueryConstraint) {
    return (await this.queryDocs(route, arg1)).docs.map((value) => {
      let data = value.data()
      data['key'] = value.id
      if (data['archive'] && data['archive'] == true) return null
      return data;
    }).filter((value) => value !== null)
  }

  async getGlobalDocs(route: string): Promise<QuerySnapshot<DocumentData>> {
    return await getDocs(collection(firestore, `${route}`))
  }
  getListDocs() {
    throw new Error('Method not implemented.');
  }
  logEvent(log: any, arg1: any = null) {
    if (environment.production) logEvent(analytics, log, arg1)
  }

  getPath(route: string): string {
    return `clients/${this.clientId}/${route}`;
  }

  saveMetaOfFile(fileObj: FileMetaData) {

  }


  routeApproval(route: any): string {
    return ''
  }

  online: boolean = false;
  personRef: User | null = null;
  personRole: string | null = null;
  clientId: string | null = null;
  clientConnected: boolean = false;
  loadUser: BehaviorSubject<any> | null = new BehaviorSubject(null)
  loadClient: BehaviorSubject<any> = new BehaviorSubject(null)
  pageService: BehaviorSubject<any> = new BehaviorSubject(null);

  /**
   * 
   * @param route 'string route where data is pushed to
   * @param data  'data to be pushed'
   * @returns 
   */
  async push(route: string, data: any): Promise<DocumentReference<any>> {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await addDoc(collection(firestore, `clients/${this.clientId}/${route}`), data)
  }

  async pushglobal(route: string, data: any): Promise<DocumentReference<any>> {
    return await addDoc(collection(firestore, `${route}`), data)
  }

  async batchWrite(route: string, list: any[]) {
    const batch = writeBatch(firestore);
    list.forEach((value) => {
      batch.set(doc(firestore, route), value)
    })
    batch.commit()
  }
  /**
   * 
   * @param route 'string route where Object document
   * @param data 'data as Object to be set'
   * @param merge 'bool if true updated unchanged values adds new values to be pushed'
   * @returns 
   */
  async set(route: string, data: any, merge: boolean) {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await setDoc(doc(firestore, `clients/${this.clientId}/${route}`), data, { merge: merge })
  }
  async setGlobal(route: string, data: any, merge: boolean) {
    return await setDoc(doc(firestore, `${route}`), data, { merge: merge })
  }
  /**
   * 
   * @param route 
   * @param data 
   * @param merge 
   * @returns 
   */
  async update(route: string, data: any, merge: boolean) {
    if (!this.clientId) await this.fetchClientId(this.user)
    return await updateDoc(doc(firestore, `clients/${this.clientId}/${route}`), data, { merge: merge })
  }

  async userMessage(message: string, duration: number = 3000) {
    const alert = await this.toastController.create({
      header: message,
      duration: duration
    });
    return await alert.present()
  }

  async userApproval(message: string, confirm: string = 'Confirm', duration: number = 3000) {
    const alert = await this.toastController.create({
      header: message,
      duration: duration,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: confirm,
          role: 'ok',
          handler: async () => {
            return alert.dismiss(true);
          }
        }
      ]
    }
    );
    await alert.present()
    const { data } = await alert.onDidDismiss()
    if (data) return true;
    return false
  }
  // local data
  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(key)
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  async getUserStage() {
    const callable = httpsCallable(functions, 'getUserStage');
    const { data } = await callable() as any;
    if (data) this.claims = data
  }

  async confirmClient(user: string | null = null) {
    if (!user) return false
    if (!this.clientId) return false

    const clientData = await (await getDoc(doc(firestore, `clients/${this.clientId}`))).data()
    if (!clientData) this.clientConnected = false;
    if (clientData && clientData['connected']) return this.clientConnected = true
    return this.clientConnected = false
  }

  async getClaims() {
    if (!this.claims) {
      if (!auth || auth && !auth.currentUser) return
      this.claims = await auth.currentUser?.getIdTokenResult().then((claims) => claims);
    }
  }

  async getClientId() {
    if (this.clientId) return this.clientId
    await this.fetchClientId();
    return this.clientId;
  }

  async loadMore() {
    if (!auth.currentUser || auth.currentUser && !auth.currentUser.uid) return
    if (this.claims.role === 'super-admin' && !environment.production) return;
    if (!this.userData) this.userData = await this.getDocData(`users/${auth.currentUser.uid}`) ?? null;
  }

  async callfunction(searchName: string, input: {} | null = null) {
    if (!searchName) return null;
    const done = httpsCallable(functions, searchName);
    const { data } = await done(input) as any;
    await Promise.all([data])
    return data as any
  }

  async fetchClientId(user: User | null = null): Promise<boolean> {
    this.log('fetching clientId')
    if (!user) {
      if (await (auth.currentUser)) this.user = await auth.currentUser as User;
    }
    if (!user) return false;
    const userData = await (await getDoc(doc(firestore, `users/${user.uid}`))).data()
    this.log('got data ',)
    this.log(userData)

    if (!userData) return false;
    if (!userData['clientId']) return false;
    this.log('found client Id ')
    this.log(userData['clientId'])
    this.clientId = userData['clientId']
    this.loadClient.next(this.clientId)
    return await this.confirmClient(this.clientId)
  }

  constructor(
    public toastController: ToastController,
    public router: Router,
  ) {
    this.pageService.subscribe(async (option) => {
      if (!this.user) this.user = await auth?.currentUser!
      if(!this.user) return;
      await this.set(`users/${this.user.uid}`, { page: option }, true).catch(this.handleError).then(() => this.userMessage('Page Selected'))
    })
    this.loadUser?.subscribe(async (user: User | null): Promise<boolean | User> => {
      if (user !== null) return this.user = user;
      if (user == null) {
        this.log('no user')
        this.online = false
        return false
      }
      this.user = user;
      if (!this.clientId) await this.fetchClientId(user)
      await this.getClaims()
      this.online = true
      return this.user
    })

    auth.onAuthStateChanged(async (user) => {
      this.personRef = user;
      await this.fetchClientId(user)
      if (!this.user)
      if (this.loadUser != null) this.loadUser.next(user);
      return (user);
    })
  }
  async getUser(): Promise<User | null> {
    const user = await auth.currentUser
    return user
  }

  async pushForApproval(route: string, approvalObject: { key: string; }, itemKey: string | null = null, editMode: boolean = false) {
    if (editMode && itemKey) {
      const approvals = await (await this.queryDocs(`approvals/${route}`, where('updatedBy', '==', await auth.currentUser?.uid ?? ''))).docs.map((val) => val.id)
      let matchingApprovals = [];
      if (this.router.url.includes('approval')) {
        matchingApprovals = approvals.filter((match) => {
          return match === approvalObject.key;
        });
      } else {
        matchingApprovals = approvals.filter((match) => {
          return match === itemKey;
        });
      }

      if (matchingApprovals.length === 0 || !this.router.url.includes('approval')) {
        this.push(`approvals/${route}`, approvalObject)
        this.userMessage('Pushed for Approval')
      } else {
        this.update(`approvals/${route}/${itemKey}`, approvalObject, true);
        this.userMessage('Updated for Approval')
      }
    } else {
      this.push(`approvals/${route}`, approvalObject);
      this.userMessage('Pushed for Approval')
    }
  }
}
