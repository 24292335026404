import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { logEvent } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { analytics, auth } from '../../app.module';

declare let snaptr: Function;
declare let gtag: Function;
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  inAgreement: boolean = false;
  ftag: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private toast: ToastController) { }

  async init() {
    const agreement = await localStorage.getItem('analytics_agreement');
    if(agreement === 'accept') return this.inAgreement = true;
    return this.inAgreement;
  }

  public initfb(id) {
    if (isPlatformBrowser(this.platformId) === true) {
      this.ftag = id
      if (fbq && fbq !== undefined || null) {
        fbq('init', id);
      }
    }
  }

  public initsnap(id) {
    if (isPlatformBrowser(this.platformId) === true) {
      this.ftag = id
      if (fbq && fbq !== undefined || null) {
        snaptr('init', id);
      }
    }
  }

  public snapinit(pixel, type: string, data) {
    const snap = snaptr
    if (environment.production) { 
        if(snaptr && snaptr !== undefined || null) {
          if(type && data !== undefined || null) {
            snaptr('init', pixel, {[type]: data});
          } else {
            snaptr('init', pixel);
          }
        } else {
          let snapobj = {[type]: data}
          let initsnap = "snaptr('init'," + pixel + "," + snapobj + ");"
          let snap = document.createElement('script')
              snap.setAttribute('type', 'text/javascript')
              snap.textContent = `<!-- Snap Pixel Code -->
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');
              `;{initsnap};`
              <!-- End Snap Pixel Code -->`
              this.document.head.appendChild(snap)
        }
    }
  }

  public initgtg(id) {
    console.log(id)
    var script_tag = this.document.createElement('script');
    script_tag.async = true;
    script_tag.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=" + id);
    script_tag.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', ${id});`
    this.document.head.appendChild(script_tag);
    script_tag.onload = (value) => {
      console.log(value)
      /*
      var script2 = this.document.createElement('script');
          script2.text =`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', ${id});`
          this.document.head.appendChild(script2);
          script2.onload = (two) => {console.log(two)}
          */
      console.log(this.document);
    }
  }

  async userApproval(message: string, confirm: string = 'Confirm',  duration: number = 3000) {
    const result = await this.init()
    if(result) return;
    const alert = await this.toast.create({
      header: message,
      duration: duration,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: confirm,
          role: 'ok',
          handler: async () => {
            return alert.dismiss(true);
          }
        }
      ]
    }
    );
    await alert.present()
    const { data } = await alert.onDidDismiss()
    if (data) return true;
    return false
  }

  async showAgreement() {
    if(this.inAgreement) return;
    const result = await this.init()
    if(result === true) return;
    const approve = await this.userApproval('We use google analytics for conversion tracking', 'Accept', 16000)
    if(approve) return this.acceptAgreement();
    if(!approve) return this.declineAgreement()
    return this.inAgreement
  }

  async getAgreement() {
    const result = await localStorage.getItem('analytics_agreement')
    if(result) return this.inAgreement = true
    return this.inAgreement = false;
  }

  async acceptAgreement() {
    await localStorage.setItem('analytics_agreement', 'accept');
    return this.inAgreement = true
  }
  
  async declineAgreement() {
    await localStorage.setItem('analytics_agreement', 'decline');
    this.inAgreement = false;
  }

  async logEvent(name: string = '', log: {} = {}) {
    if(!this.inAgreement) {
      const result = await this.showAgreement()
      if(result === true) this.sendEvent(name, log)
    } else {
      this.sendEvent(name, log)
    }
  }

  async snapActivity(activity: string) {

  }

  public snaptrack(pixel, type: string, data) {
    const snap = snaptr
    const snappixel = pixel ? pixel : 'ba260fc7-4b55-437c-b025-f508281b78da';
    if (environment.production) { 
        if(snaptr && snaptr !== undefined || null) {
          if(!pixel) {
            snaptr({[type]: data})
          } else {
            snaptr({[type]: data});
          }
        } else {
          let snapobj = {'user_email': auth.currentUser.email }
          let initsnap = "snaptr('init'," + snappixel + "," + snapobj + ");"
          let snap = document.createElement('script')
              snap.setAttribute('type', 'text/javascript')
              snap.textContent = `<!-- Snap Pixel Code -->
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');
              `;{initsnap};`
              `;{snapobj};`
              <!-- End Snap Pixel Code -->`
              document.head.appendChild(snap)
        }
    }
  }

  public firelitics(log: any, event) {
    if (environment.production) {
      if (log && !event || event == null) {
        this.logEvent(log, null)
      };
      if (log && event || event != null) {
        this.logEvent(log, event)
      }
    }
  }

  private sendEvent(name: string = '', log: {} = {}) {
    if(!name || log === {}) return 
    if(!environment.production) return console.info('sendEvent > ', name, log)
    if(environment.production) return logEvent(analytics, name, log);
  }
}