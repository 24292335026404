import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'year'
})
export class YearPipe implements PipeTransform {

  transform(dates) {
    //console.log(dates)
    var updated_dates = dates.map(date => {
      var integer = parseInt(date.time, 10)
      var d = new Date(integer);
      return d.getFullYear(); 
    });
    
    var count = dates.map(date => {
      function a (ResultArray){
        var counts={};
        var p = 0
        for ( p = 0; p < ResultArray.length; p++){            
            counts[ResultArray[p]] = (counts[ResultArray[p]] + 1) || 1;            
        }
        return counts;
    }
    
    var arr = updated_dates        
    var res =  a(arr);
    //console.log(res)
    return res
    })
    let stats = {
      year: Array.from(new Set(updated_dates,)),
      number: count
    }
    //console.log(stats)
    return Array.from(new Set(updated_dates,));
  }

}
