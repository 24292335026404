import { NgModule } from '@angular/core';
import { YearPipe } from '../pipes/year.pipe';
import { MonthPipe } from '../pipes/month.pipe';
import { HourPipe } from '../pipes/hour.pipe';
import { DayPipe } from '../pipes/day.pipe';
import { SearchPipe } from '../pipes/search.pipe';
import { StylizePipe } from '../pipes/stylize.pipe';
import { SortPipe } from '../pipes/sort.pipe';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SafeResourcePipe } from '../pipes/safe-resource.pipe';
import { StylePipe } from '../dashboard/style/pipes/style-pipe.pipe';
import { BorderSortPipe } from '../dashboard/style/pipes/border-sort.pipe';

const pipes = [
    SortPipe,
    SafeHtmlPipe,
    SafeResourcePipe,
    SearchPipe,
    YearPipe,
    MonthPipe,
    HourPipe,
    DayPipe,
    StylizePipe,
    StylePipe,
    BorderSortPipe
]

@NgModule({
    declarations: [
        pipes
    ],
    imports: [
        CommonModule
    ],
    exports: [
        pipes
    ]
    
})

export class PipesModules { }