import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hour'
})
export class HourPipe implements PipeTransform {

  transform(dates) {
    console.log(dates)
    var updated_dates = dates.map(date => {
      var integer = parseInt(date.time, 10)
      var d = new Date(integer);
      return d.getHours(); 
    });
    
    console.log(updated_dates)   
    
    var arr = updated_dates
    var obj = { };
    for (var i = 0, j = arr.length; i < j; i++) {
      if (obj[arr[i]]) {
          obj[arr[i]]++;
      }
      else {
          obj[arr[i]] = 1;
      } 
    }
    console.log(obj);
    

     for (var i = 1; i <= 24; i++) {
      if (!obj.hasOwnProperty(i.toString())) {
        obj[i.toString()] = 0;
      }
  }

    console.log(obj);
    return Object.keys(obj).map((k) => obj[k])
  }

}
