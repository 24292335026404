import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { logEvent } from 'firebase/analytics';
import { User } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, DocumentData, DocumentReference, DocumentSnapshot, getDoc, getDocs, query, QueryConstraint, QuerySnapshot, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { BehaviorSubject, Subject } from 'rxjs';
import { analytics, auth, firestore } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

export interface FileMetaData {
  id: string,
  name: string,
  size: number,
  file: File
}

@Injectable({
  providedIn: 'root',
})

export class FirebaseService {
  claims: any;
  userData: DocumentData;
  user: User = null;

  async handleError(err, message: string = '') {
    console.info(err)
   // await this.userMessage(message != '' ? message : 'Whoops, Something went wrong please contact us')
  }

  async getRef(route: string) {
    return await doc(firestore, route).withConverter(null)
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where params
   * @returns 
   */
  async queryDocs(route: string, arg1: QueryConstraint): Promise<QuerySnapshot<DocumentData>> {
    console.info(`queryDocs > `, this.clientId)
    if(!this.clientId) return
    return getDocs(query(collection(firestore, `clients/${this.clientId}/${route}`), arg1))
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where params
   * @returns 
   */
  queryGlobalDocs(route: string, arg1: QueryConstraint): Promise<QuerySnapshot<DocumentData>> {
    return getDocs(query(collection(firestore, `${route}`), arg1))
  }
  
  aDoc(route: string): Promise<DocumentSnapshot<DocumentData>> {
    return getDoc(doc(firestore, `clients/${this.clientId}/${route}`))
  }

  async aGlobalDoc(route: string): Promise<DocumentData> {
    if(!this.clientId) return
    return await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`))).data()
  }
  /**
   * 
   * @param route /path to Object
   * @returns 
   */
  async getDocData(route: string): Promise<DocumentData> {
    if(!this.clientId) return
    const value = await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`)))
    if(!value.exists) return null;
    let data = value.data()
    if(!data) return null;
    data.key = value.id
    return data
  }
  async getGlobalDocData(route: string): Promise<DocumentData> {
    return await (await getDoc(doc(firestore, `${route}`))).data()
  }

  async getDocId(route: string): Promise<string> {
    if(!this.clientId) return
    return await (await getDoc(doc(firestore, `clients/${this.clientId}/${route}`))).id
  }
  /**
   * 
   * @param arg0 route to Object
   * @returns 
   */
  async removeDoc(route: string) {
    if(!this.clientId) return
    return await deleteDoc(doc(firestore,  `clients/${this.clientId}/${route}`));
  }
  /**
   * 
   * @param route path to Object
   * @returns Object
   */
  async object(route) {
    return await this.getDocData(route)
  }
  /**
   * 
   * @param route path to Array of objects
   * @returns Array<any>
   */
  async getDocs(route: string): Promise<QuerySnapshot<DocumentData>> {
    if(!this.clientId) return
    return await getDocs(collection(firestore, `clients/${this.clientId}/${route}`))
  }

  /**
   * 
   * @param route route of data to be archived
   * @returns void
   */
  async archive(route: string) {
    return await this.set(route, { 'archive': true }, true)
  }
  /**
   * 
   * @param route route to Array
   * @returns Array<any>
   */
  async list(route: string) {
    return (await this.getDocs(route)).docs.map((value) => {
      let data = value.data()
      data.key = value.id
      if (data.archive && data.archive == true) return null
      return data;
    }).filter((value) => value != null)
  }
  /**
   * 
   * @param route route to Array
   * @param arg1 firebase where query
   * @returns Array<any>
   */
  async listquery(route: string, arg1: QueryConstraint) {
    return (await this.queryDocs(route, arg1)).docs.map((value) => {
      let data = value.data()
      data.key = value.id
      if (data.archive && data.archive == true) return null
      return data;
    }).filter((value) => value !== null)
  }
  
  async getGlobalDocs(route: string): Promise<QuerySnapshot<DocumentData>> {
    return await getDocs(collection(firestore, `${route}`))
  }
  getListDocs() {
    throw new Error('Method not implemented.');
  }
  logEvent(log: any, arg1: any) {
    logEvent(analytics, log, arg1)
  }

  getPath(route: string): string {
    return `clients/${this.clientId}/${route}`;
  }

  saveMetaOfFile(fileObj: FileMetaData) {

  }


  routeApproval(route): string {
    return ''
  }

  online: boolean = false;
  personRef: User;
  personRole: string;
  clientId: string = null;
  clientConnected: boolean = false;
  loadUser: BehaviorSubject<User | null> = new BehaviorSubject(null)
  loadClient: BehaviorSubject<string | null> = new BehaviorSubject(null)
  pageService: BehaviorSubject<any> = new BehaviorSubject(null);
  /**
   * 
   * @param route 'string route where data is pushed to
   * @param data  'data to be pushed'
   * @returns 
   */
  async push(route: string, data: any): Promise<DocumentReference<any>> {
    if(!this.clientId) return
    return await addDoc(collection(firestore, `clients/${this.clientId}/${route}`), data)
  }

  async pushglobal(route: string, data: any): Promise<DocumentReference<any>> {
    return await addDoc(collection(firestore, `${route}`), data)
  }

  async batchWrite(route: string, list: any[]) {
    const batch = writeBatch(firestore);
    list.forEach((value) => {
      batch.set(null, value)
    })
    batch.commit()
  }
  /**
   * 
   * @param route 'string route where Object document
   * @param data 'data as Object to be set'
   * @param merge 'bool if true updated unchanged values adds new values to be pushed'
   * @returns 
   */
  async set(route: string, data: any, merge: boolean) {
    if(!this.clientId) return
    return await setDoc(doc(firestore, `clients/${this.clientId}/${route}`), data, { merge: merge })
  }
  async setGlobal(route: string, data: any, merge: boolean) {
    return await setDoc(doc(firestore, `${route}`), data, { merge: merge })
  }
  /**
   * 
   * @param route 
   * @param data 
   * @param merge 
   * @returns 
   */
  async update(route: string, data: any, merge: boolean) {
    if(!this.clientId) return
    return await updateDoc(doc(firestore, `clients/${this.clientId}/${route}`), data, { merge: merge })
  }

  async userMessage(message: string) {
    const alert = await this.toastController.create({
      header: message,
      duration: 3000
    });
    return await alert.present()
  }
  // local data
  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(key)
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  async getClaims(user) {
    if (!this.claims) this.claims = await (await user.getIdTokenResult()).claims
    if(this.claims.role === 'super-admin' && !environment.production) return;
    if (!this.userData) this.userData = await this.getDocData(`users/${user.uid}`)
  }

  async fetchClientId(user: User): Promise<boolean> {
    console.info('fetching clientId')
    if (!user) return false
    console.info('fetchClientId path > ', `users/${user.uid}`)
    this.getGlobalDocData( `users/${user.uid}`).then(async (userData) => {
      console.info('got data ', userData)
      if (!userData.clientId) return false;
      console.info('found client Id ', userData.clientId)
      this.clientId = userData.clientId
      //return await this.confirmClient(this.clientId)
    })
    /*()
    return await getDoc(doc(firestore, `users/${user.uid}`)).then(async (data: DocumentSnapshot<DocumentData>) => {
      if (!data.exists) return false;
      const userData = data.data();
      console.info('got data ', userData)
      if (!userData.clientId) return false;
      console.info('found client Id ', userData.clientId)
      this.clientId = userData.clientId
      return await this.confirmClient(this.clientId)
    })
    */
  }

  async confirmClient(user) {
    if (!user) return false
    if (!this.clientId) return false
    console.info('got clientId > ', this.clientId)
    /*
    const clientData = await (await getDoc(doc(firestore, `clients/${this.clientId}`))).data()
    if (!clientData) this.clientConnected = false;
    if (clientData && clientData.connected) return this.clientConnected = true
    return this.clientConnected = false
    */
  }

  constructor(
    public toastController: ToastController,
    public router: Router,
  ) {
    this.pageService.subscribe(async (option) => {
      if(!this.user) this.user = await this.getUser()
      await this.set(`users/${this.user.uid}`, { page: option }, true).catch(this.handleError).then(() => this.userMessage('Page Selected'))
    })
    this.loadUser.subscribe(async (user: User | null): Promise<boolean | User> => {
      console.info('load user was called', user !== null ? ' ' : ' with no user')
      if (user == null) return this.online = false
      this.user = user;
      if (!this.clientId) await this.fetchClientId(user)
      await this.getClaims(user)
      this.online = true
      return this.user
    })

    auth.onAuthStateChanged(async (user) => {
      this.personRef = user;
      if(!this.user) this.loadUser.next(user)
      return
      if (user) {
        this.online = true
        this.claims = await (await user.getIdTokenResult()).claims
        await this.confirmClient(this.clientId)
        if(!this.clientId) return;
        this.userData = await this.getDocData(`users/${user.uid}`)
      } else {
        this.online = false
      }
    })
  }
  async getUser(): Promise<User | null> {
    const user = await auth.currentUser
    return user
  }

  async pushForApproval(route: string, approvalObject, itemKey: string = null, editMode: boolean = false) {
    if (editMode && itemKey) {
      const approvals = await (await this.queryDocs(`approvals/${route}`, where('updatedBy', '==', auth.currentUser.uid))).docs.map((val) => val.id)
      let matchingApprovals = [];
      if (this.router.url.includes('approval')) {
        matchingApprovals = approvals.filter((match) => {
          return match === approvalObject.key;
        });
      } else {
        matchingApprovals = approvals.filter((match) => {
          return match === itemKey;
        });
      }

      if (matchingApprovals.length === 0 || !this.router.url.includes('approval')) {
        this.push(`approvals/${route}`, approvalObject)
        this.userMessage('Pushed for Approval')
      } else {
        this.update(`approvals/${route}/${itemKey}`, approvalObject, true);
        this.userMessage('Updated for Approval')
      }
    } else {
      this.push(`approvals/${route}`, approvalObject);
      this.userMessage('Pushed for Approval')
    }
  }
}